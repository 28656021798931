import * as React from "react";
import { PrimaryButton } from "@fluentui/react";
import { Stack } from '@fluentui/react/lib/Stack';
import { DetailsList, SelectionMode, buildColumns, IconButton, IColumn, ChoiceGroup   } from '@fluentui/react';
import Header from "../../Header";
// import TableComponent from "../../TableComponent";
import NavigationLink from "../../NavigationLink";
import Layout from "../../../layouts";
import useAuthenticatedFetch from "../../../../hooks/useAuthenticatedFetch";
import Spinner from "../../Spinner";
import useNavigate from "../../../../hooks/useNavigate";
import RoamingSettingsHelper from "../../../../helpers/roamingSettingsHelper";

export default function TemplateListing() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [templates, setTemplates] = React.useState(null);

  const authenticatedFetch = useAuthenticatedFetch();
  const { navigateToPage } = useNavigate();

  React.useEffect(() => {
    loadTemplates();
  }, []);

  const loadTemplates = async () => {
    try {
      const data = await authenticatedFetch('templates', {
        method: 'GET',
        // Other fetch options as needed
      });

      if(data && data.length > 0) {
        let templates = data.map((row) => {
          return {
            id: row.template_id,
            title: row.title,
            content: row.content
          }
        });

        setTemplates(templates);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setTemplates(null);
      }

    } catch (error) {
      console.error('Error fetching data:', error.message);
      setIsLoading(false);
    }
  }

  const handleEditTemplate = (template) => {
    navigateToPage(`settings/templates/edit`, { template });
  }

  const handleDeleteTemplate = async (templateId) => {
    const data = await authenticatedFetch(`templates/${templateId}`, {
      method: 'DELETE',
      // Other fetch options as needed
    });
    loadTemplates();
  }
  
  return (
    <Layout
      header={
        <Header title="Templates" backLink="sendsms" />
      }
    >
      { isLoading 
        ? 
          <Spinner />
        :
          templates && templates.length
          ?
            <TableComponent data={templates} editCallback={handleEditTemplate} deleteCallback={handleDeleteTemplate}/>
          :
            <p style={{textAlign: "center"}}>You haven't added any templates yet.</p>
      }
        <Stack styles={styles.buttonRow}>
          <NavigationLink style={styles.button} text="Add New" targetPage="settings/templates/new"/>
        </Stack>
    </Layout>
  )
}

const TableComponent = (props) => {
  const { data } = props;
  const [defaultTemplate, setDefaultTemplate] = React.useState(null);
  React.useEffect(() => {
    async function getDefaultTemplate() {
      try {
        const templateId = await RoamingSettingsHelper.get("default_template");
        if(templateId) {
          setDefaultTemplate(templateId);
        }
      } catch(error) {
        console.log("Error getting default template", error);
      }
    }

    getDefaultTemplate();
  }, [])

  const handleEdit = (item) => {
    props.editCallback(item);
  };

  const handleDelete = (item) => {
    props.deleteCallback(item.id);
  };

  const onSelectDefault = async (id) => {
    try {
      if(id) {
        await RoamingSettingsHelper.set("default_template", id);
        setDefaultTemplate(id);
      }
    } catch(error) {
      console.log("Error saving default template", error);
    }
  }

  const columns = [
    {
      key: 'setDefault',
      name: 'Default',
      fieldName: 'setDefault',
      minWidth: 30,  // Adjust the minimum width to make it smaller
      maxWidth: 30,
      onRender: (item) => {(
        <ChoiceGroup
          selectedKey={defaultTemplate}
          options={[
            {
              key: item.id,
              // text: 'Set Default',
            },
          ]}
          onChange={() => {
            onSelectDefault(item.id)
          }}
        />
      )},
    },
    {
      key: 'title',
      // name: 'Title',
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'actions',
      // name: 'Actions',
      fieldName: 'actions',
      minWidth: 60,
      maxWidth: 60,
      isResizable: false,
      onRender: (item) => {
        return (
          <div>
            <IconButton iconProps={{ iconName: 'Edit' }} title="Edit" onClick={() => handleEdit(item)} />
            <IconButton iconProps={{ iconName: 'Delete' }} title="Delete" onClick={() => handleDelete(item)} />
          </div>
        );
      },
    },
  ];

  return (
    <DetailsList
      items={data}
      columns={columns}
      selectionMode={SelectionMode.none}
      ariaLabelForSelectAllCheckbox="Toggle selection for all items"
      ariaLabelForSelectionColumn="Toggle selection"
    />
  );
};

const styles = {
  input: {
    
  },
  buttonRow: {
    display: "flex",
    justifyContent: "flex-end",
    flexFlow: "row",
  },
  button: {
    width: "40%"
  }
}