import userService from "../services/UserService";

const fetchContactsFromGroup = async (groupId) => {
    try {
        const user = await userService.getUser();
        // console.log("user.accessToken.token", user.accessToken.token);
        const response = await fetch(`https://graph.microsoft.com/v1.0/groups/${groupId}/members`, {
            headers: {
            "Authorization": `Bearer ${user.accessToken.token}`
            // 'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        const contacts = await response.json();
        // console.log("contacts", contacts)
        return contacts.value;
    } catch(err) {
        console.error("Error occured while fetching folders from Graph API: ", err);
        return false;
    }

}

const fetchDistributionListFromMSGraph = async () => {
    try {
        const user = await userService.getUser();
        // console.log("user.accessToken.token", user.accessToken.token);
        const response = await fetch("https://graph.microsoft.com/v1.0/me/memberOf", {
            headers: {
            "Authorization": `Bearer ${user.accessToken.token}`
            // 'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        const data = await response.json();
        // console.log("response", data)
        if(data?.value) {
            return data.value;
        } else {
            return false;
        }
    } catch(err) {
        console.error("Error occured while fetching folders from Graph API: ", err);
        return false;
    }

}

const fetchContactsFromMSGraph = async () => {
    try {
        const user = await userService.getUser();
        // console.log("user.accessToken.token", user.accessToken.token);
        const response = await fetch('https://graph.microsoft.com/v1.0/me/contacts', {
            headers: {
            "Authorization": `Bearer ${user.accessToken.token}`
            // 'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        const contacts = await response.json();
        return contacts.value;
    } catch(err) {
        console.error("Error occured while fetching contacts from Graph API: ", err);
        return false;
    }

}

const fetchEventsFromMSGraph = async () => {
    try {
        const user = await userService.getUser();
        const response = await fetch('https://graph.microsoft.com/v1.0/me/events', {
            headers: {
            "Authorization": `Bearer ${user.accessToken.token}`
            // 'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        const contacts = await response.json();
        return contacts.value;
    } catch(err) {
        console.error("Error occured while fetching events from Graph API: ", err);
        return false;
    }

}

export {
    fetchContactsFromMSGraph,
    fetchEventsFromMSGraph,
    fetchDistributionListFromMSGraph,
    fetchContactsFromGroup
}