import * as React from "react";
import Progress from "./components/Progress";
import { useSelector, useDispatch } from 'react-redux'
import { setUser } from './redux/userSlice'
import userService from '../services/UserService'
import StartTrial from "./components/StartTrial";
import Login from './components/Login';
import SendSMS from "./components/SendSMS";
import Settings from "./components/Settings";
import TemplateListing from "./components/Settings/Templates";
import AddNewTemplate from "./components/Settings/Templates/AddNew";
import SignatureListing from "./components/Settings/Signatures";
import AddNewSignature from "./components/Settings/Signatures/AddNew";
import MyAccount from "./components/Settings/MyAccount";
import useAuthenticatedFetch from "../hooks/useAuthenticatedFetch";
import RoamingSettingsHelper from "../helpers/roamingSettingsHelper";

export default function App(props) {
  const { title, isOfficeInitialized, isReplyMode } = props;
  const user = useSelector((state) => state.user.value)
  const currentPage = useSelector((state) => state.currentPage.value);
  const currentPageData = useSelector((state) => state.currentPage.pageData);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [showTrial, setShowTrial] = React.useState(false);
  const authenticatedFetch = useAuthenticatedFetch();

  React.useEffect(() => {
    async function init() {
      try {
        setIsLoading(true);
        await Promise.all([
          loadUser(),
          checkUserAccount(),
        ])
        setIsLoading(false);
      } catch(err) {
        console.log("Unable to load Addin", err);
        setIsLoading(false);
      }
    }

    init();
  }, [])

  /**
   * Check if user account exists with email address
   */
  const checkUserAccount = async () => {
    try {
      let userEmail = Office.context.mailbox.userProfile.emailAddress;
      if(!isOfficeInitialized || !userEmail) {
        throw new Error("Unable to get current user email address");
      }

      let data = {
        email: userEmail
      }
      const response = await authenticatedFetch('common/find-account', {
        method: 'POST',
        body: JSON.stringify(data),
      });
      console.log("account response", response)
      if(!response || !response.user) {
        setShowTrial(true);
      }
      
      if(response) {
      }
    } catch(err) {
      console.log("Error fetching account by email: ", err);
    }
  }

  /**
   * Check if user is already logged in
   */
  const loadUser = async () => {
    let userData = await userService.getUser();
    setUserDefaultTimezone();
    dispatch(setUser(userData));
  }

  const setUserDefaultTimezone = async () => {
    try {
      // check if timezone is already selected
      let selectedTimezone = await RoamingSettingsHelper.get("timezone");
      if(!selectedTimezone) {
        const data = await authenticatedFetch('common/account-info', {
          method: 'GET',
        });
        if(data && data.timezone?.timezone_id) {
          await RoamingSettingsHelper.set("timezone", JSON.stringify(data.timezone));
        }
      }
    } catch(err) {
      console.log("Error fetching default timezone", err);
    }
  }

  if (!isOfficeInitialized) {
    return (
      <Progress
        title={title}
        logo={require("../../assets/logo-filled.png")}
        message="Please sideload your addin to see app body."
      />
    );
  }

  if(isLoading) {
    return (
      <Progress
          title={title}
          logo={require("../../assets/logo-filled.png")}
          message="Please wait."
        />
    )
  } else if(showTrial) {
    return (
      <StartTrial />
    )
  } else if(!user) {
    return (
      <Login />
    )
  }
  
  if(!isLoading && user) {

    let component;
    switch (currentPage) {
      case 'sendsms':
        component = <SendSMS isReplyMode={isReplyMode}/>;
        break;
      case 'settings':
        component = <Settings />;
        break;
      case 'settings/templates':
        component = <TemplateListing />;
        break;
      case 'settings/templates/new':
        component = <AddNewTemplate />;
        break;
      case 'settings/templates/edit':
        component = <AddNewTemplate editMode={true} pageData={currentPageData} />;
        break;
      case 'settings/signatures':
        component = <SignatureListing />;
        break;
      case 'settings/signatures/new':
        component = <AddNewSignature />;
        break;
      case 'settings/signatures/edit':
        component = <AddNewSignature editMode={true} pageData={currentPageData} />;
        break;
      case 'settings/my-account':
        component = <MyAccount />;
        break;
      default:
        component = <Settings />;
        break;
    }

    return (
      <div className="ms-welcome">
        {component}
      </div>
    )
  }
}