const API_URL = BACKEND_URL + "office365/";
const AUTH_URL = BACKEND_URL + "office365/oauth/signin/";
const TOKEN_REFRESH_URL = BACKEND_URL + "office365/oauth/refreshToken/"
const FRONTEND_REDIRECT_URL = `${FRONTEND_URL}auth.html`;

console.log("constants", BACKEND_URL, FRONTEND_URL)
export {
    AUTH_URL,
    FRONTEND_REDIRECT_URL,
    TOKEN_REFRESH_URL,
    API_URL
}
