
import { useDispatch } from 'react-redux';
import { setCurrentPage } from '../taskpane/redux/currentPageSlice';

const useNavigate = () => {
    const dispatch = useDispatch();

    // Define your custom action dispatch functions here
    const navigateToPage = (targetPage, pageData) => {
        dispatch(setCurrentPage({
            page: targetPage,
            data: pageData
        }));
    };

    // You can add more dispatch functions as needed

    return {
        navigateToPage
        // Add other dispatch functions to this object as needed
    };
};

export default useNavigate;