import * as React from "react";
import { Link, PrimaryButton } from "@fluentui/react";
import { Stack } from '@fluentui/react/lib/Stack';
import { useSelector, useDispatch } from 'react-redux'
import { ActionButton } from '@fluentui/react/lib/Button';
import Header from "../Header";
import Layout from "../../layouts";
import useNavigate from "../../../hooks/useNavigate";

export default function Settings() {
  const user = useSelector((state) => state.user.value);
  
  const { navigateToPage } = useNavigate();

  const goToPage = (page) => {
    navigateToPage(page);
  }

  const handleClickOnLink = () => {
    window.open("https://redoxygen.com/resources/faqs/")
  }
  
  return (
    <Layout
      header={
        <Header title={"Settings"} backLink={"sendsms"} />
      }
    >
      <PrimaryButton style={styles.button} text="Manage Templates" onClick={() => goToPage("settings/templates")} />
      <PrimaryButton style={styles.button} text="Manage Signatures" onClick={() => goToPage("settings/signatures")} />
      <PrimaryButton style={styles.button} text="My Account" onClick={() => goToPage("settings/my-account")}/>
      <Link style={styles.helpLink} underline onClick={handleClickOnLink}>
        Need Help?
      </Link>
    </Layout>
  )
}
  
// Styles definition
const styles = {
  button: {
    marginTop: "40px"
  },
  actionButton: {
    flexContainer: {
      justifyContent: "center"
    },
  },
  helpLink: {
    width: "100%",
    textAlign: "center",
    marginTop: "60px"
  }
}