import * as React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import {
  NormalPeoplePicker,
  PeoplePickerItem,
  ValidationState,
} from '@fluentui/react/lib/Pickers';
import {
  fetchDistributionListFromMSGraph
} from "../../helpers/graphAPIHelper";
import { Text } from '@fluentui/react/lib/Text';
import { PeoplePickerPerson } from "../../types/index";

const suggestionProps = {
  suggestionsHeaderText: 'Suggested People',
  mostRecentlyUsedHeaderText: 'Frequent / Recent',
  noResultsFoundText: 'No results found',
  loadingText: 'Loading',
  showRemoveButtons: true,
  suggestionsAvailableAlertText: 'People Picker Suggestions available',
  suggestionsContainerAriaLabel: 'Suggested contacts',
};

const checkboxStyles = {
  root: {
    marginTop: 10,
  },
};

export const PeoplePickerNormalExample = React.forwardRef((props, ref) => {
  const [delayResults, setDelayResults] = React.useState(false);
  const [isPickerDisabled, setIsPickerDisabled] = React.useState(false);
  const [showSecondaryText, setShowSecondaryText] = React.useState(false);
  const [mostRecentlyUsed, setMostRecentlyUsed] = React.useState([]);
  const [peopleList, setPeopleList] = React.useState([]);
  const [selectedGroups, setSelectedGroups] = React.useState(props.selectedGroups);
  // const peoplePickerRef = props.ref;
  const peoplePickerRef = React.useRef(null);

  React.useEffect(() => {
    setSelectedGroups(props.selectedGroups);
  }, [props.selectedGroups])

  React.useEffect(() => {
    async function fetchDistributionLists() {
      let distributionLists = await fetchDistributionListFromMSGraph();

      // const groups = await fetchGroupsFromGraphAPI(filter);
      if(distributionLists && distributionLists.length > 0) {
        const filteredGroups = distributionLists.filter((group) => !!group.displayName)
        const personaPropsArray = filteredGroups.map((group) => ({
          key: group.id,
          text: group.displayName,
        }));

        setPeopleList(personaPropsArray);
        setMostRecentlyUsed(personaPropsArray);
        props.onGroupsLoaded();
      }
    }
    fetchDistributionLists();
  }, []);

  const handlePickerChange = (groups) => {
    setSelectedGroups(groups);
    props.handleChange(groups);
  };

  const onFilterChanged = (
    filterText,
    currentPersonas,
    limitResults,
  ) => {
    if (filterText) {
      let filteredPersonas = filterPersonasByText(filterText);

      filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
      filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
      return filterPromise(filteredPersonas);
    } else {
      return [];
    }
  };

  const filterPersonasByText = (filterText) => {
    let matchedPersonas = peopleList.filter(person => matchPersonByText(person, filterText));
    if(matchedPersonas && matchedPersonas.length) {
      return matchedPersonas;
    }
  };

  const setSelections = (personas) => {
    if(personas && personas.length > 0) {
      personas.map(function(item) {
        peoplePickerRef.current.completeSelection(item);
      })
      return true;
    }
  }

  const filterPromise = (personasToReturn) => {
    if (delayResults) {
      return convertResultsToPromise(personasToReturn);
    } else {
      return personasToReturn;
    }
  };

  const returnMostRecentlyUsed = (currentPersonas) => {
    return filterPromise(removeDuplicates(mostRecentlyUsed, currentPersonas));
  };

  const onRemoveSuggestion = (item) => {
    const indexPeopleList= peopleList.indexOf(item);
    const indexMostRecentlyUsed = mostRecentlyUsed.indexOf(item);

    if (indexPeopleList >= 0) {
      const newPeople = peopleList
        .slice(0, indexPeopleList)
        .concat(peopleList.slice(indexPeopleList + 1));
      setPeopleList(newPeople);
    }

    if (indexMostRecentlyUsed >= 0) {
      const newSuggestedPeople = mostRecentlyUsed
        .slice(0, indexMostRecentlyUsed)
        .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
      setMostRecentlyUsed(newSuggestedPeople);
    }
  };

  const renderItemWithSecondaryText = (props) => {
    const newProps = {
      ...props,
      item: {
        ...props.item,
        ValidationState: ValidationState.valid,
        showSecondaryText: true,
      },
    };

    return <PeoplePickerItem {...newProps} />;
  };

  if(!peopleList || peopleList.length <= 0 ) {
    return "";
  }

  return (
    <div>
      <NormalPeoplePicker
        // eslint-disable-next-line react/jsx-no-bind
        selectedItems={selectedGroups}
        onResolveSuggestions={onFilterChanged}
        // eslint-disable-next-line react/jsx-no-bind
        onEmptyInputFocus={returnMostRecentlyUsed}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={suggestionProps}
        className={`ms-PeoplePicker ${props.errorMessage && 'invalid'}`}
        key={'normal'}
        // eslint-disable-next-line react/jsx-no-bind
        onRemoveSuggestion={onRemoveSuggestion}
        onRenderItem={showSecondaryText ? renderItemWithSecondaryText : undefined}
        onValidateInput={validateInput}
        selectionAriaLabel={'Selected contacts'}
        removeButtonAriaLabel={'Remove'}
        onChange={handlePickerChange}
        inputProps={{
          'aria-label': 'People Picker',
          'placeholder': 'Choose Groups...'
        }}
        componentRef={peoplePickerRef}
        onInputChange={onInputChange}
        resolveDelay={300}
        disabled={isPickerDisabled}
        errorMessage={props.errorMessage}
      />
      { props.errorMessage &&
        <Text className="error-message" variant='small' nowrap block>
          {props.errorMessage}
        </Text>
      }
    </div>
  );
});

function matchPersonByText(person, filterText) {
  // console.log("doesTextStartWith", text, filterText)
  // return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;

  let matched = false;

  if(person.text.toLowerCase().indexOf(filterText.toLowerCase()) === 0) {
    matched = true;
  }

  if(person.mobilePhone.indexOf(filterText) !== -1) {
    matched = true;
  }

  return matched;
}

function removeDuplicates(personas, possibleDupes) {
  if(personas) {
    return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
  }

  return [];
}

function listContainsPersona(persona, personas) {
  if (!personas || !personas.length || personas.length === 0) {
    return false;
  }
  return personas.filter(item => item.text === persona.text).length > 0;
}

function convertResultsToPromise(results) {
  return new Promise((resolve, reject) => setTimeout(() => resolve(results), 2000));
}

function getTextFromItem(persona) {
  return persona.text;
}

function validateInput(input) {
  if (input.indexOf('@') !== -1) {
    return ValidationState.valid;
  } else if (input.length > 1) {
    return ValidationState.warning;
  } else {
    return ValidationState.invalid;
  }
}

/**
 * Takes in the picker input and modifies it in whichever way
 * the caller wants, i.e. parsing entries copied from Outlook (sample
 * input: "Aaron Reid <aaron>").
 *
 * @param input The text entered into the picker.
 */
function onInputChange(input) {
  const outlookRegEx = /<.*>/g;
  const emailAddress = outlookRegEx.exec(input);

  if (emailAddress && emailAddress[0]) {
    return emailAddress[0].substring(1, emailAddress[0].length - 1);
  }

  return input;
}

PeoplePickerNormalExample.propTypes = {
  handleChange: PropTypes.func,
  errorMessage: PropTypes.string,
  selectedGroups: PropTypes.array
};

export default PeoplePickerNormalExample;