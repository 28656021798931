import * as React from "react";
import { Spinner } from '@fluentui/react/lib/Spinner';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';

const CustomSpinner = ({ message }) => {
    return (
        <Stack style={styles.spinner}>
            <Spinner label={ message || "Please wait." } />
        </Stack>
    )
}

const styles = {
    spinner: {
        marginTop: 10,
        marginBottom: 10
    }
}

export default CustomSpinner;