import * as React from "react";
import { Link } from "@fluentui/react";
import { Stack } from '@fluentui/react/lib/Stack';
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment-timezone";
import { Dropdown, DropdownMenuItemType } from '@fluentui/react/lib/Dropdown';
import Header from "../../Header";
import Layout from "../../../layouts";
import useNavigate from "../../../../hooks/useNavigate";
import useAuthenticatedFetch from "../../../../hooks/useAuthenticatedFetch";
import Spinner from "../../Spinner";
import RoamingSettingsHelper from "../../../../helpers/roamingSettingsHelper";

export default function Settings() {
  const user = useSelector((state) => state.user.value);
  const [isLoading, setIsLoading] = React.useState(true);
  const [accountDetails, setAccountDetails] = React.useState(null);
  const [selectedTimezone, setSelectedTimezone] = React.useState(null);
  const [timezones, setTimezones] = React.useState([]);
  const { navigateToPage } = useNavigate();
  const authenticatedFetch = useAuthenticatedFetch();
  React.useEffect(() => {
    fetchUserAccountDetails();
    getTimezones();
    loadDefaultTimezone();
  }, [])

  const loadDefaultTimezone = async () => {
    try {
        let timezone = await RoamingSettingsHelper.get("timezone");
        if(timezone) {
            timezone = JSON.parse(timezone);
            setSelectedTimezone(timezone.timezone_id);
        }
    } catch(error) {
        console.log("Error loading default timezone", error);
    }
  }

  const fetchUserAccountDetails = async () => {
    try {
      const data = await authenticatedFetch('common/account-info', {
        method: 'GET',
        // Other fetch options as needed
      });
      setAccountDetails(data);
      setIsLoading(false);
    } catch(err) {
      console.log("Error fetching account details");
      setIsLoading(false);
    }
  }

  const getTimezones = async () => {
    try {
      const data = await authenticatedFetch('common/timezones', {
        method: 'GET',
      });

      if(data && data.length > 0) {
        let timezones = data
        setTimezones(timezones);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error.message);
      setIsLoading(false);
    }
  }

  const handleTimezoneSelect = async (evt, timezone) => {
    try {
      setSelectedTimezone(timezone.key);
      await RoamingSettingsHelper.set("timezone", JSON.stringify(timezone));
    } catch(err) {
      console.log("Error saving timezone", err);
    }
  }

  const handleClickOnLink = () => {
    window.open("https://awsad.redoxygen.net/")
  }
  
  return (
    <Layout
      header={
        <Header title={"My Account"} backLink={"sendsms"} />
      }
    >
        {isLoading === true && 
          <Spinner />
        }
        {(isLoading === false && accountDetails) && 
          <>
            {/* <h3 style={{textAlign: "center"}}>Welcome, Vishal!</h3> */}
            <div className="account-details">
                <p>
                    <span>Email:</span>
                    <span>{accountDetails.email}</span>
                </p>
                <p>
                    <span>Account ID:</span>
                    <span>{accountDetails.accountId}</span>
                </p>
                <p>
                    <span>SMS Remaining:</span>
                    <span>{accountDetails.messagesRemaining}</span>
                </p>
                <Dropdown
                  label="Timezone"
                  defaultSelectedKey={selectedTimezone}
                  notifyOnReselect={true}
                  onChange={handleTimezoneSelect}
                  placeholder="Select Timezone"
                  options={timezones.map((timezone) => {
                    return {
                      key: timezone.timezone_id,
                      text: timezone.timezone_name,
                      ...timezone
                    }
                  })}
                />
            </div>
            <Link style={styles.helpLink} underline onClick={handleClickOnLink}>
              Go to my CSMS Account
            </Link>
          </>
        }
    </Layout>
  )
}
  
// Styles definition
const styles = {
  button: {
    marginTop: "40px"
  },
  helpLink: {
    width: "100%",
    textAlign: "center",
    marginTop: "40px"
  }
}