import * as React from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Link, DefaultButton } from "@fluentui/react";
import useNavigate from "../../../hooks/useNavigate";
// import "./index.css";

export default function DropdownMenu(props) {

    const { navigateToPage } = useNavigate();
    const dropdownRef = React.useRef(null);

    React.useEffect(() => {
        // add event listener to close the dropdown menu
        const eventListener = (event) => {
            console.log("target >>>", event.target)
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                props.onClose();
            }
        }
        document.body.addEventListener("click", eventListener);

        return () => {
            document.body.removeEventListener("click", eventListener)
        }
    })

    const goToPage = (page) => {
        navigateToPage(page);
    }

    const handleClickOnLink = () => {
        window.open("https://redoxygen.com/resources/faqs/")
    }

    return (
        <div className="dropdown-menu" ref={dropdownRef}>
            <div className="nav-link-outer">
                <DefaultButton className="nav-link" text="Manage Templates" onClick={() => goToPage("settings/templates")} />
                <DefaultButton styles={{borderRadius: 0}} className="nav-link" text="Manage Signatures" onClick={() => goToPage("settings/signatures")} />
                <DefaultButton className="nav-link" text="My Account" onClick={() => goToPage("settings/my-account")} />
            </div>
        </div>
    )
}