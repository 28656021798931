import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: "sendsms",
  pageData: null
}

export const currentPageSlice = createSlice({
  name: 'currentPage',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.value = action.payload.page;
      state.pageData = action.payload.data;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCurrentPage } = currentPageSlice.actions

export default currentPageSlice.reducer