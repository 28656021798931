import * as React from "react";
import { PrimaryButton } from "@fluentui/react";
import { Stack } from '@fluentui/react/lib/Stack';
import Header from "./Header";
import { useSelector, useDispatch } from 'react-redux'
import UserService from '../../services/UserService'
import { setUser } from '../redux/userSlice'
import Layout from "../layouts";

export default function StartTrial(props) {
  const user = useSelector((state) => state.user.value);
  const dispatch = useDispatch();

  const click = () => {
    let trialUrl = "https://redoxygen.com/free-trial/";
    window.open(trialUrl, "_blank");
  }
    
  return (
    <Layout className="start-trial">
      <h2 style={{textAlign: "center"}}>Create Trial Account</h2>
      <ul class="rox-features">
        <li>No Obligation</li>
        <li>No Credit Card Required</li>
        <li>15-Day Trial</li>
        <li>25 Free SMS Credits</li>
        <li>Access to Complete SMS</li>
        <li>Free Live Support</li>
      </ul>
      <PrimaryButton className="ms-welcome__action" onClick={click}>
        Start Your Free Trial
      </PrimaryButton>
    </Layout>
  )
}