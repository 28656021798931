import {TOKEN_REFRESH_URL} from "../config/constants";
import RoamingSettingsHelper from "../helpers/roamingSettingsHelper";

class UserService {
    saveUser = async (data) => {
        if(data.accessToken) {
            const result = await RoamingSettingsHelper.set("rox_user", JSON.stringify(data));
            return result
        };
    }

    getUser = async () => {
        var userDetails = await RoamingSettingsHelper.get("rox_user");
        // console.log("get user >>>", userDetails);
        if (userDetails && typeof userDetails !== undefined) {
            try {
                userDetails = JSON.parse(userDetails);
                let isTokenValid = this.isAccessTokenValid(userDetails);
                if(isTokenValid) {
                    return userDetails;
                } else {
                    const updatedUser = await this.refreshAccessToken(userDetails);
                    return updatedUser;
                }
            } catch(err) {
                console.log("Failed to parse user from roaming settings", err);
                return false;
            }
        } else {
            console.log("User not found");
            return false;
        }
    }

    deleteUser = () => {
        const result = RoamingSettingsHelper.delete("rox_user");
        return result;
    }

    isAccessTokenValid = (user) => {
        let tokenExpiration = new Date(user.accessToken.expiresAt).getTime();
        let dateTimeNow = new Date().getTime();

        const timeDifferenceMs = tokenExpiration - dateTimeNow;

        // Convert milliseconds to minutes
        const timeDifferenceMinutes = Math.round(timeDifferenceMs / (1000 * 60)); // 1000 ms in a second, 60 seconds in a minute

        console.log(`Token expires in approximately ${timeDifferenceMinutes} minutes.`);


        if(dateTimeNow > tokenExpiration) {
            return false;
        } else {
            return true;
        }
    }

    refreshAccessToken = async (user) => {
        try {
            const data = {
                auth_provider: "microsoft",
                auth_user: user.email
            }
            const response = await fetch(TOKEN_REFRESH_URL, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken.token}`
                }
            });

            const accessToken = await response.json();
            if(accessToken) {
                user.accessToken = {
                    token: accessToken.token,
                    expiresAt: accessToken.expiresAt
                };
                const updatedUser = await this.saveUser(user);
                return user;
            }

            return false;
        } catch(err) {
            console.log("Failed to refresh access token: ", err);
            return false;
        }
    }
}

const userService = new UserService();

export default userService;