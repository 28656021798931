import * as React from "react";
import PropTypes from "prop-types";
import { PrimaryButton } from "@fluentui/react";
import { TextField, MaskedTextField } from '@fluentui/react/lib/TextField';
import PeoplePicker from './PeoplePicker';
import DistributionListPicker from './GroupPicker'
import {
  Link,
  MessageBar,
  MessageBarType,
} from '@fluentui/react';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import {
  fetchContactsFromMSGraph,
  fetchEventsFromMSGraph,
  fetchContactsFromGroup
} from "../../helpers/graphAPIHelper";
import SMSService from "../../services/ROXSMSService";
import { util, encoder, segmenter } from "../../helpers/smsSplit";
import { DatePickerComponent } from "./DatePicker";
import { PeoplePickerPerson } from "../../types";
import Header from "./Header";
import Layout from "../layouts";
import Spinner from "./Spinner";
import useAuthenticatedFetch from "../../hooks/useAuthenticatedFetch";
import RoamingSettingsHelper from "../../helpers/roamingSettingsHelper";
import moment from "moment";

export default function SendSMS({ isReplyMode }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [people, setPeople] = React.useState(null);
  const [selectedPersons, setSelectedPersonas] = React.useState([]);
  const [selectedGroups, setSelectedGroups] = React.useState();
  const [smsTextValue, setSmsTextValue] = React.useState('');
  const [messageCount, setMessageCount] = React.useState(1);
  const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);
  const [charCount, setCharCount] = React.useState(0);
  const [successMsg, setSuccessMsg] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [isSendingMessage, setIsSendingMessage] = React.useState(false);
  const [sendLaterChecked, setSendLaterChecked] = React.useState(false);
  const [sendLaterDateTime, setSendLaterDateTime] = React.useState(null);
  const [peoplePickerErrorMsg, setPeoplePickerErrorMsg] = React.useState(null);
  const [smsTextErrorMsg, setSmsTextErrorMsg] = React.useState(null);
  const [sendLaterDateTimeErrorMsg, setSendLaterDateTimeErrorMsg] = React.useState(null);
  const [templates, setTemplates] = React.useState([]);
  const [signatures, setSignatures] = React.useState([]);
  const [selectedSignature, setSelectedSignature] = React.useState(null);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const [contactsWarning, setContactsWarning] = React.useState(null);
  const [groupsLoaded, setGroupsLoaded] = React.useState(false);
  const [userTimezone, setUserTimezone] = React.useState(null);
  const peoplePickerRef = React.useRef(null);
  const smsTextAreaRef = React.useRef(null);

  const authenticatedFetch = useAuthenticatedFetch();
  
  const picker = React.useRef(null);

  React.useEffect(() => {
      loadTemplates();
      loadSignatures();
      loadTimezone();
  }, [])

  React.useEffect(async () => {
    // await RoamingSettingsHelper.delete("timezone");
    // userService.deleteUser();
    if (!people) {
      try {
        let events = await fetchEventsFromMSGraph();
        let contacts = await fetchContactsFromMSGraph();
        let peopleList = contacts.map(function (contact, i) {
          let person = new PeoplePickerPerson(contact.mobilePhone, contact.displayName, contact.emailAddresses[0], i + 1);
          return person;
        })

        setPeople(peopleList);
        setIsLoading(false);
      } catch(err) {
        console.error("Error while fetching data from Graph API:", err);
        setIsLoading(false);
        setPeople([]);
      }
    }
  }, [people]);

  React.useEffect(() => {
    setCharCount(smsTextValue.length);
  }, [smsTextValue]);

  React.useEffect(() => {
    if (selectedSignature && signatures.length) {
      loadSelectedSignature(selectedSignature);
    }
  }, [selectedSignature]);
  
  React.useEffect(() => {
    if (selectedTemplate && templates.length) {
      loadSelectedTemplate(selectedTemplate);
    }
  }, [selectedTemplate]);

  const loadTimezone = async () => {
    try {
      let timezone = await RoamingSettingsHelper.get("timezone");
      if(timezone) {
        timezone = JSON.parse(timezone);
        setUserTimezone(timezone)
      }
    } catch(err) {
      console.log("Error getting user timezone", err);
    }
  }

  const getTimezoneDateOffset = async (selectedTimezone, date) => {
    try {
      const data = await authenticatedFetch(`common/find-offset`, {
        method: 'POST',
        body: JSON.stringify({
          selectedTimezone,
          date,
        }),
      });

      return data;
    } catch (error) {
      console.log("Error fetching timezone date offset", error);
    }
  }

  const loadTemplates = async () => {
    const templates = await authenticatedFetch('templates', {
      method: 'GET'
    });

    if(templates && templates.length > 0) {
      setTemplates(templates);

      if(!selectedTemplate) {
        const selectedTemplate = await RoamingSettingsHelper.get("default_template");
        if(selectedTemplate) {
          setSelectedTemplate(selectedTemplate);
        }
      }
    }
  }

  const loadSignatures = async () => {
    const signatures = await authenticatedFetch('signatures', {
      method: 'GET'
    });

    if(signatures && signatures.length > 0) {
      setSignatures(signatures);

      if(!selectedSignature) {
        const selectedSignature = await RoamingSettingsHelper.get("default_signature");
        if(selectedSignature) {
          setSelectedSignature(selectedSignature);
        }
      }
    }
  }

  const handleTemplateSelect = (event, template) => {
    if(template && template.content) {
      setSmsTextValue(template.content);
      setSelectedSignature(null);
      setSelectedTemplate(template.template_id);
    }
  }

  const handleSignatureSelect = (event, signature) => {
    if(signature && signature.signature_id) {
      setSelectedSignature(signature.signature_id);
    }
  }

  const loadSelectedSignature = (id) => {
    let signature = signatures.filter((signature) => signature.signature_id == id);
    if( signature && signature.length > 0 ) {
      signature = signature[0];
      let newMsgContents = `${signature.signature_start}\n\n${smsTextValue}\n\n${signature.signature_end}`
      setSmsTextValue(newMsgContents);
    }
  }

  const loadSelectedTemplate = (id) => {
    let template = templates.filter((template) => template.template_id == id);
    if( template && template.length > 0 ) {
      template = template[0];
      setSmsTextValue(template.content);
    }
  }

  const handleEmojiSelect = (selectedEmoji) => {
    insertEmojiAtCursor(smsTextAreaRef.current, selectedEmoji.native);
    setShowEmojiPicker(false);
  }

  const insertEmojiAtCursor = (myField, myValue) => {
    //IE support
    let newValue = '';
    if (document.selection) {
      myField.focus();
      sel = document.selection.createRange();
      sel.text = myValue;
    }
    //MOZILLA and others
    else if (myField.selectionStart || myField.selectionStart == '0') {
      var startPos = myField.selectionStart;
      var endPos = myField.selectionEnd;
      newValue = myField.value.substring(0, startPos)
        + myValue
        + myField.value.substring(endPos, myField.value.length);
    } else {
      newValue = myField.value + myValue;
    }

    setSmsTextValue(newValue);
    resetMessages();
    setSmsTextErrorMsg();
  }

  const toggleEmojiPicker = (e) => {
    e.preventDefault
    setShowEmojiPicker(!showEmojiPicker);
  }


  const handleSubmit = async () => {
    try {
      setIsSendingMessage(true);
      // clear existings messages
      resetMessages();

      let isValid = true;

      // validate inputs
      if (!smsTextValue || smsTextValue.length == 0) {
        setSmsTextErrorMsg("Please enter your message");
        isValid = false;
      }

      if ((!selectedPersons || selectedPersons.length == 0) && (!selectedGroups || selectedGroups.length === 0)) {
        setPeoplePickerErrorMsg("Please select a contact");
        isValid = false;
      }

      if(selectedGroups && selectedGroups.length > 0){
        // fetch all contacts from the selected groups
        const allGroupsContacts = await getContactsFromGroups(selectedGroups);

        if(allGroupsContacts) {
          //   selectedPersons = [...selectedPersons, ...allGroupsContacts];
          let groupPeopleList = allGroupsContacts.map(function (contact, i) {
            let phone = contact.mobilePhone || contact.businessPhones[0];
            let person = new PeoplePickerPerson(phone, contact.displayName, contact.mail, i + 1);
            return person;
          })
          Array.prototype.push.apply(selectedPersons,groupPeopleList);
        }
      }

      // get the timezone offset if send later is checked and a datetime is selected
      let offset = null;
      if (sendLaterChecked) {
        if (!sendLaterDateTime) {
          setSendLaterDateTimeErrorMsg("Please select a Date and Time");
          isValid = false;
        } else {
          let selectedTimezone = await RoamingSettingsHelper.get("timezone");
          selectedTimezone = JSON.parse(selectedTimezone);
          let formattedDate = moment(sendLaterDateTime).format("YYYY-MM-DD");
          let timezoneOffset = await getTimezoneDateOffset(selectedTimezone, formattedDate);
          if(timezoneOffset && timezoneOffset.length > 0) {
            let selectedTimezoneOffset = selectedTimezone?.timezone_bias;
            selectedTimezoneOffset = selectedTimezoneOffset * -1;
            offset = selectedTimezoneOffset + timezoneOffset[0]?.time_offset;
          }
        }
      }

      if (!isValid) {
        setIsSendingMessage(false);
        return;
      }

      let smsOptions = {
        content: smsTextValue,
        contacts: selectedPersons,
        offset
      }

      if (sendLaterChecked && sendLaterDateTime) {
        smsOptions.sendLater = sendLaterDateTime;
      }

      try {
        let response = await SMSService.enqueueSMS(smsOptions);
        setSuccessMsg("SMS sent successfully.");
        resetSendSMSForm();
      } catch (error) {
        console.error(error);
        setErrorMsg("Failed to send SMS");
      }
      setIsSendingMessage(false);
    } catch(err) {
      console.error("error occurred while sending SMS");
      setIsSendingMessage(false);
    }
  }

  const getContactsFromGroups = async (groups) => {
    let groupContacts = [];
    if(groups && groups.length > 0) {
      const output = await Promise.all(groups.map(async (group) => {
          const contacts = await fetchContactsFromGroup(group.key);
          return contacts;
      }))

      // filter contacts
      let filteredContacts = output.filter((contacts) => {
        if(typeof contacts != "undefined" && contacts.length) {
          return true;
        }

        return false;
      });

      // check for duplicate contacts and add contacts to global array
      filteredContacts.map(function(contacts) {
        const checkForExistingContact = (contact) => {
          const findContact = groupContacts.filter((item) => {
            return item.id == contact.id;
          })

          if(findContact && findContact.length > 0) {
            return true;
          }

          return false;
        }
        contacts.map((contact) => {
          const contactExists = checkForExistingContact(contact);
          if(!contactExists) {
            groupContacts.push(contact);
          }
        })
      })

      return groupContacts;
    }

    return false;
  }

  const resetSendSMSForm = () => {
    // reset peoplePicker 
    setSelectedPersonas([]);
    setSelectedGroups([]);
    setSmsTextValue('');
    setSendLaterChecked(false);
  }

  const handleSMSTextChange = (e) => {
    const value = e.target.value;
    var input = value, encoding = "auto";
    let inputCharacters = util.unicodeCharacters(input);
    let encodedChars = encoder[encoding](input);
    let smsSegments = segmenter[encoding](inputCharacters);
    let isEncoded = util.map(smsSegments, function (segment) {
      return util.map(segment.bytes, function (c) {
        return c === undefined ? "not-encoded" : ""
      })
    });

    setSmsTextValue(value);
    setMessageCount(smsSegments.length)
    resetMessages();
    setSmsTextErrorMsg(null);
  }

  const handleSelectDateTime = (dateTime) => {
    setSendLaterDateTime(dateTime);
    setSendLaterDateTimeErrorMsg(null);
  }

  const handleChangeSelectedPersonas = (personas) => {
    personas = personas.slice(0, 100);
    setSelectedPersonas(personas);
    resetMessages();
    setPeoplePickerErrorMsg(null);
  }

  const handleGroupSelection = (selectedGroups) => {
    setSelectedGroups(selectedGroups);
  };

  const resetMessages = () => {
    setSuccessMsg('');
    setErrorMsg('');
  }

  if (!people) {
    return (
      <Layout
        header={
          <Header logo={require("./../../../assets/logo-filled.png")} title={"OfficeSMS 365"} topBtn={true} topBtnLink={"settings"} />
        }
      >
        <Spinner />
      </Layout>
    )
  }

  return (
    <Layout
      header={
        <Header logo={require("./../../../assets/logo-filled.png")} title={""} topBtn={true} topBtnLink={"settings"} />
      }
      className="send-sms"
    >
      {successMsg
        &&
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
        >
          {successMsg}
        </MessageBar>
      }
      {errorMsg
        &&
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
        >
          {errorMsg}
        </MessageBar>
      }

      <PeoplePicker
        people={people}
        selectedPersons={selectedPersons}
        handleChange={handleChangeSelectedPersonas}
        errorMessage={peoplePickerErrorMsg}
        isReplyMode={isReplyMode}
        ref={peoplePickerRef}
      />
      { groupsLoaded && 
        <p style={{textAlign: "center", marginTop: "10px", marginBottom: 0 }}>Or</p>
      }
      <DistributionListPicker
        selectedGroups={selectedGroups}
        handleChange={handleGroupSelection}
        onGroupsLoaded={() => {
          setGroupsLoaded(true);
        }}
      />

      {/* <TextField placeholder="Standard" errorMessage="Error message" required/> */}
      <TextField
        ref={smsTextAreaRef}
        placeholder="Text Message"
        multiline={true}
        onChange={handleSMSTextChange}
        rows={8}
        value={smsTextValue}
        errorMessage={smsTextErrorMsg}
        className="sms-textarea"
      />
      {/* <Link onClick={toggleEmojiPicker}>
            Add Emoji
          </Link> */}
      {/* <Panel
        headerText="Add Emoji"
        isOpen={showEmojiPicker}
        onDismiss={() => setShowEmojiPicker(false)}
        // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        closeButtonAriaLabel="Close"
      >
        <Picker
          data={data}
          onEmojiSelect={handleEmojiSelect}
          className="emoji-picker"
          style={{ maxWidth: '350px' }}
          perLine={7}
        />
      </Panel> */}
      <p style={{ marginBottom: 0 }}>SMS Characters: {charCount} / Message count: {messageCount}</p>
      <Dropdown
        label=""
        disabled={!templates.length}
        defaultSelectedKey={selectedTemplate}
        notifyOnReselect={true}
        onChange={handleTemplateSelect}
        placeholder="Select a Template"
        options={templates.map((template) => {
          return {
            key: template.template_id,
            template_id: template.template_id,
            text: template.title,
            content: template.content,
          }
        })}
      />
      <Dropdown
        label=""
        disabled={!signatures.length}
        defaultSelectedKey={selectedSignature}
        notifyOnReselect={true}
        onChange={handleSignatureSelect}
        placeholder="Select a Signature"
        options={signatures.map((signature) => {
          return {
            key: signature.signature_id,
            signature_id: signature.signature_id,
            text: signature.title,
            signature_start: signature.signature_start,
            signature_end: signature.signature_end
          }
        })}
      />
      <Checkbox label="Send Later" checked={sendLaterChecked} onChange={() => setSendLaterChecked(!sendLaterChecked)} />
      {sendLaterChecked &&
        <DatePickerComponent handleSelectDateTime={handleSelectDateTime} errorMessage={sendLaterDateTimeErrorMsg} />
      }
      <PrimaryButton style={styles.sendButton} text={isSendingMessage ? "Sending" : "Send"} onClick={handleSubmit} allowDisabledFocus disabled={isSendingMessage} />
    </Layout>
  )
}

// Styles definition
const stackTokens = { childrenGap: 50 };
const iconProps = { iconName: 'Calendar' };
const stackStyles = { root: { justifyContent: 'center' } };
const columnProps = {
  tokens: { childrenGap: 15, marginTop: '20' },
  styles: { root: { width: 300, justifyContent: 'center', margin: 20 } },
};
const styles = {
  input: {
  },
  sendButton: {
    marginTop: 30
  }
}

SendSMS.propTypes = {
  isReplyMode: PropTypes.bool
};