import * as React from "react";
import { DetailsList, SelectionMode, buildColumns, IconButton, IColumn, ChoiceGroup   } from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import Header from "../../Header";
import NavigationLink from "../../NavigationLink";
import Layout from "../../../layouts";
import useAuthenticatedFetch from "../../../../hooks/useAuthenticatedFetch";
import Spinner from "../../Spinner";
import useNavigate from "../../../../hooks/useNavigate";
import RoamingSettingsHelper from "../../../../helpers/roamingSettingsHelper";

export default function SignatureListing() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [signatures, setSignatures] = React.useState(null);

  const authenticatedFetch = useAuthenticatedFetch();
  const { navigateToPage } = useNavigate();

  React.useEffect(() => {
    loadSignatures();
  }, []);

  const loadSignatures = async () => {
    try {
      const data = await authenticatedFetch('signatures', {
        method: 'GET',
        // Other fetch options as needed
      });

      if(data && data.length > 0) {
        let signatures = data.map((row) => {
          return {
            signature_id: row.signature_id,
            title: row.title,
            signature_start: row.signature_start,
            signature_end: row.signature_end
          }
        });

        setSignatures(signatures);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error.message);
      setIsLoading(false);
      setSignatures(null);
    }
  }

  const handleEditSignature = (signature) => {
    navigateToPage(`settings/signatures/edit`, { signature });
  }

  const handleDeleteSignature = async (signatureId) => {
    const data = await authenticatedFetch(`signatures/${signatureId}`, {
      method: 'DELETE',
      // Other fetch options as needed
    });
    loadSignatures();
  }

  const setDefault = (id, isDefault) => {
    const updatedSignatures = signatures.map((signature) => {
      if(signature.id == id) {
        signature.isDefault = isDefault;
      } else {
        signature.isDefault = false;
      }
      return signature;
    })

    setSignatures(updatedSignatures);
  }
  
  return (
    <Layout
      header={
        <Header title="Signature" backLink="sendsms" />
      }
    >
      { isLoading 
        ? 
          <Spinner />
        :
        signatures && signatures.length
          ?
            <>
              {/* <p class="default-label">Default</p> */}
              <TableComponent setDefault={setDefault} data={signatures} editCallback={handleEditSignature} deleteCallback={handleDeleteSignature}/>
            </>
          :
            <p style={{textAlign: "center"}}>You haven't added any signatures yet.</p>
      }
        <Stack styles={styles.buttonRow}>
          <NavigationLink style={styles.button} text="Add New" targetPage="settings/signatures/new"/>
        </Stack>
    </Layout>
  )
}

const TableComponent = (props) => {
  const { data } = props;
  const [defaultSignature, setDefaultSignature] = React.useState(null);

  React.useEffect(() => {
    async function getDefaultSignature() {
      const defaultSignature = await RoamingSettingsHelper.get("default_signature");
      if(defaultSignature) {
        setDefaultSignature(defaultSignature);
      }
    }

    getDefaultSignature();
  }, [])

  const handleEdit = (item) => {
    props.editCallback(item);
  };

  const handleDelete = (item) => {
    props.deleteCallback(item.signature_id);
  };

  const onSelectDefault = async (id) => {
    if(id) {
      const result = await RoamingSettingsHelper.set("default_signature", id);
      setDefaultSignature(id);
    }
  }

  const columns = [
    {
      key: 'setDefault',
      name: 'Default',
      fieldName: 'setDefault',
      minWidth: 30,  // Adjust the minimum width to make it smaller
      maxWidth: 30,
      onRender: (item) => {
      return (
        
        <ChoiceGroup
          selectedKey={defaultSignature} // Ensure a unique key for each radio button
          options={[
            {
              key: item.signature_id,
              // text: 'Set Default',
            },
          ]}
          onChange={() => onSelectDefault(item.signature_id)}
        />
      )},
    },
    {
      key: 'title',
      // name: 'Title',
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'actions',
      // name: 'Actions',
      fieldName: 'actions',
      minWidth: 60,
      maxWidth: 60,
      isResizable: false,
      onRender: (item) => {
        return (
          <div>
            <IconButton iconProps={{ iconName: 'Edit' }} title="Edit" onClick={() => handleEdit(item)} />
            <IconButton iconProps={{ iconName: 'Delete' }} title="Delete" onClick={() => handleDelete(item)} />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <p style={styles.colHeader}>Default</p>
      <DetailsList
        items={data}
        columns={columns}
        selectionMode={SelectionMode.none}
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        ariaLabelForSelectionColumn="Toggle selection"
        style={styles.table}
      />
    </>
  );
}

const styles = {
  input: {
    
  },
  buttonRow: {
    display: "flex",
    justifyContent: "flex-end",
    flexFlow: "row",
  },
  button: {
    width: "40%"
  },
  table: {
    marginTop: "10px"
  },
  colHeader: {
    margin: 0,
    fontWeight: 700,
    color: "#707070",
  }
}