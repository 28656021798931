class RoamingSettings {
    set = (key, value) => {
        return new Promise((resolve, reject) => {
            if(key && value) {
                Office.context.roamingSettings.set(key, value);
                Office.context.roamingSettings.saveAsync(function(result) {
                    if (result.status === Office.AsyncResultStatus.Succeeded) {
                        console.log("Roaming settings saved successfully");
                        resolve(value);
                    } else {
                        console.log("Failed to save roaming settings: " + result.error.message);
                        reject(new Error("Failed to save roaming settings"));
                    }
                });
            } else {
                reject(new Error("Failed to save roaming settings: invalid Parameters"));
            }
        })
    }

    get = async (key) => {
        var data = Office.context.roamingSettings.get(key);
        if (data !== undefined) {
            return data;
        } else {
            console.log("Setting not found");
            return false;
        }
    }

    delete = (key) => {
        return new Promise((resolve, reject) => {
            Office.context.roamingSettings.remove(key);
            Office.context.roamingSettings.saveAsync((result) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    console.log('Roaming setting deleted successfully.');
                    resolve(true);
                } else {
                    console.error('Error deleting roaming setting:', result.error);
                    reject(result.error);
                }
            });
            return true;
        })
    }
}

const RoamingSettingsHelper = new RoamingSettings();

export default RoamingSettingsHelper;