import * as React from "react";
import { PrimaryButton } from "@fluentui/react";
import { Stack } from '@fluentui/react/lib/Stack';
import { TextField } from '@fluentui/react/lib/TextField';
import { useSelector, useDispatch } from 'react-redux'
import { useForm, Controller } from "react-hook-form"
import {
  MessageBar,
  MessageBarType,
} from '@fluentui/react';
import { API_URL } from "../../../../config/constants";
import Header from "../../Header";
import TableComponent from "../../TableComponent";
import NavigationLink from "../../NavigationLink";
import useNavigate from "../../../../hooks/useNavigate";
import Layout from "../../../layouts";
import useAuthenticatedFetch from "../../../../hooks/useAuthenticatedFetch";

export default function AddNew({ editMode, pageData }) {
  const user = useSelector((state) => state.user.value);
  const [titleText, setTitleText] = React.useState('');
  const [signatureStart, setSignatureStart] = React.useState('');
  const [signatureEnd, setSignatureEnd] = React.useState('');
  const editingSignature = editMode && pageData?.signature;

  // let editingSignature = null;

  React.useEffect(() => {
    if(editMode && editingSignature) {
      setTitleText(editingSignature.title);
      setSignatureStart(editingSignature.signature_start);
      setSignatureEnd(editingSignature.signature_end);
    }
  }, [])

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      title: editMode && editingSignature && editingSignature.title,
      signature_start: editMode && editingSignature && editingSignature.signature_start,
      signature_end: editMode && editingSignature && editingSignature.signature_end,
    }
  })

  const { navigateToPage } = useNavigate();

  const [successMsg, setSuccessMsg] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(null);

  const authenticatedFetch = useAuthenticatedFetch();

  const onSubmit = async (formData) => {
    if(editingSignature) {
      updateSignature(formData)
    } else {
      createNewSignature(formData);
    }
  }

  const resetForm = () => {
    setTitleText('');
    setSignatureStart('');
    setSignatureEnd('');
  }

  const createNewSignature = async (formData) => {
    // create Signature
    try {
      const data = await authenticatedFetch('signatures', {
        method: 'POST',
        body: JSON.stringify(formData),
      });

      if(data){
        setSuccessMsg("Signature saved successfully");
        navigateToPage("settings/signatures");
      }

      resetForm();
    } catch (error) {
      setErrorMsg("Failed to save signature");
      console.error(error);
    }
  }

  const updateSignature = async (formData) => {
    // create Signature
    try {
      const data = await authenticatedFetch(`signatures/${editingSignature.signature_id}`, {
        method: 'POST',
        body: JSON.stringify(formData),
      });

      if(data){
        setSuccessMsg("Signature updated successfully");
        navigateToPage("settings/signatures");
      }

      resetForm();
    } catch (error) {
      console.log("Error", error);
      setErrorMsg("Failed to update Signature");
      console.error(error);
    }
  }
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Layout
        header={
          <Header title={editMode ? "Edit Signature": "Add New"} backLink={"settings/signatures"} />
        }
      >
        { successMsg 
          &&
          <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={false}
          >
            { successMsg }
          </MessageBar> 
        }
        { errorMsg 
          &&
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
          >
            { errorMsg }
          </MessageBar> 
        }

        <Controller
          name="title"
          control={control}
          value={titleText}
          rules={validation.rules.title}
          render={({ field }) => {
            return (
            <TextField
              placeholder="Title"
              {...field}
              defaultValue={titleText} // Assign the state value to the input
              onChange={(e) => {
                field.onChange(e); // This is important to update the form state
                setTitleText(e.target.value); // Update titleText as needed
              }}
              errorMessage={errors.title && validation.messages.title[errors.title.type]}
            />
          )
        }}
        />

        {/* Start Signature content */}
        <Controller
          name="signature_start"
          control={control}
          value={signatureStart}
          rules={validation.rules.signature}
          render={({ field }) => {
            return (
              <TextField 
                placeholder="Signature: start of the message."
                multiline={true}
                rows={8}
                {...field}
                defaultValue={signatureStart} // Assign the state value to the input
                onChange={(e) => {
                  field.onChange(e); // This is important to update the form state
                  setSignatureStart(e.target.value); // Update titleText as needed
                }}
                errorMessage={errors.signature_start && validation.messages.signature[errors.signature_start.type]}
              />
            )
          }}
        />

        {/* End Signature content */}
        <Controller
          name="signature_end"
          control={control}
          value={signatureEnd}
          rules={validation.rules.signature}
          render={({ field }) => {
            return (
              <TextField 
                placeholder="Signature: end of the message. Like opt out text."
                multiline={true}
                rows={8}
                {...field}
                defaultValue={signatureEnd} // Assign the state value to the input
                onChange={(e) => {
                  field.onChange(e); // This is important to update the form state
                  setSignatureEnd(e.target.value); // Update titleText as needed
                }}
                errorMessage={errors.signature_end && validation.messages.signature[errors.signature_end.type]}
              />
            )
          }}
        />

      {/* <Controller
        name="description"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            placeholder="Description"
            {...field}
          />
        )}
      />
      <Controller
        name="description_test"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            placeholder="Description"
            {...field}
          />
        )}
      /> */}

        <PrimaryButton text="Save Signature" type="submit"></PrimaryButton>
      </Layout>
    </form>
  )
}

// validation rules
const validation = {
  rules: {
    title: {
      required: true,
      // minLength: 5,
      // maxLength: 100,
    },
    signature: {
      required: true,
      // minLength: 10,
      // maxLength: 700,
    }
  },
  messages: {
    title: {
      required: "Title is required",
      minLength: "Title must be more than 5 characters",
      maxLength: "Title must be under 100 characters"
    },
    signature: {
      required: "This field is required",
      minLength: "Value must be more than 10 characters",
      maxLength: "Value must be under 700 characters"
    }
  }
}
  
// Styles definition
const stackTokens = { childrenGap: 50 };
const iconProps = { iconName: 'Calendar' };
const stackStyles = { root: { justifyContent: 'center' } };
const columnProps = {
  tokens: { childrenGap: 15, marginTop: '20' },
  styles: { root: { width: 300, justifyContent: 'center', margin: 20 } },
};
const styles = {
  input: {
    
  },
  buttonRow: {
    display: "flex",
    justifyContent: "flex-end",
    flexFlow: "row",
  },
  button: {
    width: "100%"
  }
}