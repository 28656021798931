function PeoplePickerPerson(phone, displayName, emailAddress, key) {
    phone = phone.replaceAll(" ", "");
    this.key = key;
    this.secondaryText = phone;
    this.text = displayName;
    this.mobilePhone = phone;
    this.email = emailAddress;

    // phone && phone.slice(0, 10)
}

export {
    PeoplePickerPerson
}