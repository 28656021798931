import * as React from "react";
import PropTypes from "prop-types";
import { IconButton } from '@fluentui/react/lib/Button';
import useNavigate from "../../hooks/useNavigate";
import DropdownMenu from "./DropdownMenu";

export default function Header(props) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { title, topBtn, backLink, topBtnLink, currentPage } = props;
  // console.log(title, topBtn, backLink, topBtnLink, currentPage);
  
  const { navigateToPage } = useNavigate();

  const handleButtonClick = (page) => {
    navigateToPage(page)
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  }

  return (
    <section className="ms-welcome__header ms-bgColor-neutralLighter ms-u-fadeIn500">
      { isMenuOpen &&
        <DropdownMenu onClose={handleMenuClose} />  
      }
      {/* <img width="90" height="90" src={logo} alt={title} title={title} /> */}
      {backLink &&
        <IconButton className="back-btn" iconProps={{ iconName: "Back" }} onClick={() => handleButtonClick(backLink)} />
      }
      <h1 className="ms-fontSize-su ms-fontWeight-light ms-fontColor-neutralPrimary page-title">{title}</h1>
      {topBtn && 
        <IconButton className="top-btn" iconProps={{ iconName: "Settings" }} onClick={() => toggleMenu()} />
      }
      
    </section>
  );
}

Header.propTypes = {
  title: PropTypes.string,
  logo: PropTypes.string,
  message: PropTypes.string,
  activeScreen: PropTypes.string,
};
