import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from "../config/constants";
import { setUser, logoutUser } from '../taskpane/redux/userSlice';
import userService from '../services/UserService';

const useAuthenticatedFetch = () => {
    const dispatch = useDispatch();

    const authenticatedFetch = async (endpoint, options) => {
        const user = await userService.getUser();
        if (!user) {
            throw new Error('No authenticated user');
        }

        try {
            const response = await fetch(API_URL + endpoint, {
                ...options,
                headers: {
                    ...options.headers,
                    'authorization': `Bearer ${user.accessToken.token}`,
                    'content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                if (response.status === 403 || response.status === 401) {
                    dispatch(logoutUser());
                    userService.deleteUser();
                    throw new Error('User is unauthenticated. Logging out.');
                }
                throw new Error('Network response was not ok');
            }

            if(response.status === 204) {
                return false;
            }
            
            return await response.json();
        } catch (err) {
            throw new Error(`Request failed: ${err.message}`);
        }
    };

    return authenticatedFetch;
};

export default useAuthenticatedFetch;