import * as React from "react";
import {
  PrimaryButton
} from "@fluentui/react";
import Header from "./Header";
import { useSelector, useDispatch } from 'react-redux'
import PopupModal from "./Popup";
import UserService from '../../services/UserService'
import { setUser, logoutUser } from '../redux/userSlice'
import Layout from "../layouts";
import { FRONTEND_REDIRECT_URL } from "../../config/constants";

export default function Login(props) {
  const { isOfficeInitialized } = props;
  const [tokenDetails, setToken] = React.useState(null);
  const [userDetails, setUserDetails] = React.useState(null);
  const user = useSelector((state) => state.user.value);
  const [loginProcessing, setLoginProcessing] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [popupVisible, setPopupVisible] = React.useState(false);
  const dispatch = useDispatch();
  
  React.useEffect(() => {
    if(tokenDetails && !userDetails) {
      fetch('https://graph.microsoft.com/v1.0/me', {
        headers: {
          "Authorization": `Bearer ${tokenDetails.token}`
        }
      }).then((response) => response.json())
      .then(data => {
        setUserDetails(data);
        const user = {
          accessToken: {
            token: tokenDetails.token,
            expiresAt: tokenDetails.expiresAt
          },
          email: data.mail
        }
        UserService.saveUser(user);
        dispatch(setUser(user))
      });
  }
}, [tokenDetails])

const click = async () => {
  setLoginProcessing(true);
  console.log("FRONTEND_REDIRECT_URL", FRONTEND_REDIRECT_URL);
  Office.context.ui.displayDialogAsync(
    FRONTEND_REDIRECT_URL,
    { height: 40, width: 40 },
    (result) => {
      
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        var authDialog = result.value;
        authDialog.addEventHandler(
          Office.EventType.DialogMessageReceived,
          (args) => {
            // Handle the message received from the authentication dialog
            // Extract the access token and perform API requests
            let data = JSON.parse(args.message);
            if(data?.error) {
              console.error("Error: ", data?.error)
              setErrorMsg(data?.error);
              setPopupVisible(true);
            } else {
              setToken(data);
              setLoggedIn(true);
              setLoginProcessing(false);
              authDialog.close();
            }
            setLoginProcessing(false);
          }
          );
        } else if (result.status === Office.AsyncResultStatus.Failed) {
          console.log('Failed to display dialog. Error details:', result.error);
          setLoginProcessing(false);
        }
      }
    );
  };
    
  return (
    <Layout
      header={
        <Header logo={require("./../../../assets/logo-filled.png")} title="Login" />
      }
      className="rox-login"
    >
      <div className="login-outer">
        <p className="ms-font-l">
          Login with your microsoft account to continue.
        </p>
        <PrimaryButton text={ loginProcessing ? 'Processing' : 'Login' } type="submit" onClick={click} diabled={loginProcessing}></PrimaryButton>
        <PopupModal
          popupVisible={popupVisible}
          message={errorMsg == "Cannot find the user in the database" ? "This email is not associated with any account at Red Oxygen.": "Something went wrong, please try again."}
          title={"Error"}
          onHide={() => {
            setPopupVisible(false);
          }}
        />
      </div>
    </Layout>
  )
}