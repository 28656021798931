import * as React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import {
  NormalPeoplePicker,
  PeoplePickerItem,
  ValidationState,
} from '@fluentui/react/lib/Pickers';
import { Text } from '@fluentui/react/lib/Text';
import { PeoplePickerPerson } from "../../types/index";

const suggestionProps = {
  suggestionsHeaderText: 'Suggested People',
  mostRecentlyUsedHeaderText: 'Frequent / Recent',
  noResultsFoundText: 'No results found',
  loadingText: 'Loading',
  showRemoveButtons: true,
  suggestionsAvailableAlertText: 'People Picker Suggestions available',
  suggestionsContainerAriaLabel: 'Suggested contacts',
};

const checkboxStyles = {
  root: {
    marginTop: 10,
  },
};

export const PeoplePickerNormalExample = React.forwardRef((props, ref) => {
  const [delayResults, setDelayResults] = React.useState(false);
  const [isPickerDisabled, setIsPickerDisabled] = React.useState(false);
  const [showSecondaryText, setShowSecondaryText] = React.useState(false);
  const [mostRecentlyUsed, setMostRecentlyUsed] = React.useState(props.people);
  const [peopleList, setPeopleList] = React.useState(props.people);
  const peoplePickerRef = React.useRef(null);


  React.useEffect(() => {
    if(props.isReplyMode && peopleList && peopleList.length > 0) {
      let person = getNumberFromCurrentEmail();
      if(person) {
        peoplePickerRef.current.completeSelection(person);
      }
    }

  }, [ peopleList ]);

  const handlePickerChange = (persons) => {
    props.handleChange(persons);
  };
  
  const getNumberFromCurrentEmail = () => {
    // Get the current item
    let item = Office.context.mailbox.item;
    // Check if the item is an email message
    if (item.itemType === Office.MailboxEnums.ItemType.Message) {
      // Retrieve the subject
      let emailSubject = item.subject;
      let regex = /[0-9]+/g;
      let matches = emailSubject.match(regex);
      if(matches) {
        let contactNumber = matches[0];
        let selectedPerson = {};

        // find a person from the contacts list
        let foundContact = peopleList.filter((person) => {
          return person.mobilePhone.indexOf(contactNumber) !== -1;
        });

        if(foundContact && foundContact.length > 0) {
          selectedPerson = foundContact[0];
        } else {
          selectedPerson = {
            imageInitials: '',
            imageUrl: '',
            isValid: true,
            key: 0,
            // optionalText: "Available at 4:00pm",
            // presence: 2,
            secondaryText: '',
            // tertiaryText: 'In a Meeting',
            text: contactNumber,
            mobilePhone: contactNumber,
            email: ''
          }
        }
        return selectedPerson;
      }
      
    } else {
      // Handle the case when the currently open item is not an email message
      console.log('The currently open item is not an email message.');
    }

    return null
  }

  const onFilterChanged = (
    filterText,
    currentPersonas,
    limitResults,
  ) => {
    if (filterText) {
      let filteredPersonas = filterPersonasByText(filterText);
      filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
      filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
      return filterPromise(filteredPersonas);
    } else {
      return [];
    }
  };

  const filterPersonasByText = (filterText) => {
    let matchedPersonas = peopleList.filter(person => matchPersonByText(person, filterText));
    if(matchedPersonas && matchedPersonas.length) {
      return matchedPersonas;
    } else {
      let numbersFound = findContactNumbersFromText(filterText);
      let selectedPersonas = numbersFound.map(function(number, i) {

        // find person by contact number
        let matchedPersonas = peopleList.filter(person => matchPersonByText(person, number));
        if(matchedPersonas.length > 0) {
          return matchedPersonas[0];
        } else {
          let key = peopleList.length + i + 1;
          let person = new PeoplePickerPerson(number, number, '', key);

          return person;
        }
      })

      if(selectedPersonas.length === 1 ){
        return selectedPersonas;
      } else if(selectedPersonas.length > 1) {
        if (peoplePickerRef.current) {
          setSelections(selectedPersonas);
        }
        return selectedPersonas;
      } else {
        return [];
      }
    }
  };

  const setSelections = (personas) => {
    if(personas && personas.length > 0) {
      personas.map(function(item) {
        peoplePickerRef.current.completeSelection(item);
      })
      return true;
    }
  }

  const findContactNumbersFromText = (text) => {

    const separatorRegex = /[\n, ]+/;
    const contactsArray = text.split(separatorRegex);

    // Remove any empty strings from the resulting array
    const filteredArray = contactsArray.filter(contact => {
      let contactValid = false;
      if(contact.trim() !== '') {
        const validNumberregex = /\+?[0-9]+/g;
        const matches = contact.match(validNumberregex);

        if(matches && matches.length) {
          contactValid = true;
        }
      }
      return contactValid;
    });

    if(filteredArray && filteredArray.length > 0) {
      return filteredArray;
    } else {
      return false;
    }
  }

  const filterPromise = (personasToReturn) => {
    if (delayResults) {
      return convertResultsToPromise(personasToReturn);
    } else {
      return personasToReturn;
    }
  };

  const returnMostRecentlyUsed = (currentPersonas) => {
    return filterPromise(removeDuplicates(mostRecentlyUsed, currentPersonas));
  };

  const onRemoveSuggestion = (item) => {
    const indexPeopleList= peopleList.indexOf(item);
    const indexMostRecentlyUsed = mostRecentlyUsed.indexOf(item);

    if (indexPeopleList >= 0) {
      const newPeople = peopleList
        .slice(0, indexPeopleList)
        .concat(peopleList.slice(indexPeopleList + 1));
      setPeopleList(newPeople);
    }

    if (indexMostRecentlyUsed >= 0) {
      const newSuggestedPeople = mostRecentlyUsed
        .slice(0, indexMostRecentlyUsed)
        .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
      setMostRecentlyUsed(newSuggestedPeople);
    }
  };

  const renderItemWithSecondaryText = (props) => {
    const newProps = {
      ...props,
      item: {
        ...props.item,
        ValidationState: ValidationState.valid,
        showSecondaryText: true,
      },
    };

    return <PeoplePickerItem {...newProps} />;
  };

  const onDisabledButtonClick = () => {
    setIsPickerDisabled(!isPickerDisabled);
  };

  const onToggleDelayResultsChange = () => {
    setDelayResults(!delayResults);
  };

  const onToggleShowSecondaryText = () => {
    setShowSecondaryText(!showSecondaryText);
  };

  return (
    <div>
      <NormalPeoplePicker
        // eslint-disable-next-line react/jsx-no-bind
        selectedItems={props.selectedPersons}
        onResolveSuggestions={onFilterChanged}
        // eslint-disable-next-line react/jsx-no-bind
        onEmptyInputFocus={returnMostRecentlyUsed}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={suggestionProps}
        className={`ms-PeoplePicker ${props.errorMessage && 'invalid'}`}
        key={'normal'}
        // eslint-disable-next-line react/jsx-no-bind
        onRemoveSuggestion={onRemoveSuggestion}
        onRenderItem={showSecondaryText ? renderItemWithSecondaryText : undefined}
        onValidateInput={validateInput}
        selectionAriaLabel={'Selected contacts'}
        removeButtonAriaLabel={'Remove'}
        onChange={handlePickerChange}
        inputProps={{
          'aria-label': 'People Picker',
          'placeholder': 'Add Recipients...'
        }}
        componentRef={peoplePickerRef}
        onInputChange={onInputChange}
        resolveDelay={300}
        disabled={isPickerDisabled}
        errorMessage={props.errorMessage}
      />
      { props.errorMessage &&
        <Text className="error-message" variant='small' nowrap block>
          {props.errorMessage}
        </Text>
      }
    </div>
  );
});

function matchPersonByText(person, filterText) {
  let matched = false;
  if(person.text.toLowerCase().indexOf(filterText.toLowerCase()) === 0) {
    matched = true;
  }

  if(person.mobilePhone.indexOf(filterText) !== -1) {
    matched = true;
  }

  return matched;
}

function removeDuplicates(personas, possibleDupes) {
  if(personas) {
    return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
  }

  return [];
}

function listContainsPersona(persona, personas) {
  if (!personas || !personas.length || personas.length === 0) {
    return false;
  }
  return personas.filter(item => item.text === persona.text).length > 0;
}

function convertResultsToPromise(results) {
  return new Promise((resolve, reject) => setTimeout(() => resolve(results), 2000));
}

function getTextFromItem(persona) {
  return persona.text;
}

function validateInput(input) {
  if (input.indexOf('@') !== -1) {
    return ValidationState.valid;
  } else if (input.length > 1) {
    return ValidationState.warning;
  } else {
    return ValidationState.invalid;
  }
}

/**
 * Takes in the picker input and modifies it in whichever way
 * the caller wants, i.e. parsing entries copied from Outlook (sample
 * input: "Aaron Reid <aaron>").
 *
 * @param input The text entered into the picker.
 */
function onInputChange(input) {
  const outlookRegEx = /<.*>/g;
  const emailAddress = outlookRegEx.exec(input);

  if (emailAddress && emailAddress[0]) {
    return emailAddress[0].substring(1, emailAddress[0].length - 1);
  }

  return input;
}

PeoplePickerNormalExample.propTypes = {
  isReplyMode: PropTypes.bool,
  people: PropTypes.array,
  handleChange: PropTypes.func
};

export default PeoplePickerNormalExample;