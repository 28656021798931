import React, { useState, useEffect } from 'react';
import { PrimaryButton } from '@fluentui/react';

function Popup({ popupVisible, message, title, onHide }) {
  const [isPopupVisible, setPopupVisible] = useState(popupVisible);

  useEffect(() => {
    setPopupVisible(popupVisible);
  }, [popupVisible])

  const showPopup = () => {
    setPopupVisible(true);
  };

  const hidePopup = () => {
    setPopupVisible(false);
    onHide();
  };

  return (
    <div>
      {isPopupVisible && (
        <div className="popup">
          <div className="popup-content">
            <h2 className="popup-heading">{title}</h2>
            <p>{message}</p>
            <PrimaryButton onClick={hidePopup}>Close</PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default Popup;