import App from "./App";
// import Login from "./pages/Login";
import { AppContainer } from "react-hot-loader";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { store } from './store'
import { Provider } from 'react-redux'
// import 'bootstrap/dist/css/bootstrap.min.css';
/* global document, Office, module, require */

initializeIcons();

let isOfficeInitialized = false;

const title = "Office365 SMS for Outlook";

const render = (Component) => {
  let params = new URL(document.location).searchParams;
  let mode = params.get("mode"); // New SMS / Reply
  let isReplyMode = false;

  if(mode == "reply") {
    isReplyMode = true;
  }

  if(isOfficeInitialized) {
    ReactDOM.render(
      <AppContainer>
        <Provider store={store}>
          <Component title={title} isOfficeInitialized={isOfficeInitialized} isReplyMode={isReplyMode} />
        </Provider>
      </AppContainer>,
      document.getElementById("container")
    );
  }
};

/* Render application after Office initializes */
Office.onReady(() => {
  isOfficeInitialized = true;
  // check if the user in logged in
  render(App);
  // render(Login);
  console.log("isOfficeInitialized onReady", isOfficeInitialized)
  // addComposeButton();
});

const addComposeButton = () => {
  Office.context.mailbox.item.addFileAttachmentAsync(
    'https://www.example.com/my-image.png',
    'my-image.png',
    { asyncContext: { var1: 'value1', var2: 'value2' } },
    function (asyncResult) {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        console.log('Button added successfully.');
      } else {
        console.error('Failed to add button: ' + asyncResult.error.message);
      }
    }
  );
}

/* Initial render showing a progress bar */
render(App);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(NextApp);
  });
}
