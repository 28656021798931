import * as React from "react";
import { PrimaryButton } from "@fluentui/react";
import useNavigate from "../../hooks/useNavigate";

export default function NavigationLink({text, targetPage}) {
    const { navigateToPage } = useNavigate();
    
    const handleClick = () => {
        navigateToPage(targetPage)
    }
    return (
        <PrimaryButton text={text} onClick={handleClick} />
    )
}