import * as React from 'react';
import moment from 'moment';
import {
  DatePicker,
  DayOfWeek,
  Dropdown,
  mergeStyles,
  defaultDatePickerStrings,
  TimePicker,
  Text,
} from '@fluentui/react';

const days = [
  { text: 'Sunday', key: DayOfWeek.Sunday },
  { text: 'Monday', key: DayOfWeek.Monday },
  { text: 'Tuesday', key: DayOfWeek.Tuesday },
  { text: 'Wednesday', key: DayOfWeek.Wednesday },
  { text: 'Thursday', key: DayOfWeek.Thursday },
  { text: 'Friday', key: DayOfWeek.Friday },
  { text: 'Saturday', key: DayOfWeek.Saturday },
];
const rootClass = mergeStyles({ maxWidth: 300, selectors: { '> *': { marginBottom: 0 } } });
const timePickerStyles = {
    root: {
        maxWidth: 300,
    },
  };

export const DatePickerComponent = (props) => {
  const [firstDayOfWeek, setFirstDayOfWeek] = React.useState(DayOfWeek.Sunday);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const today = new Date();

  const onDropdownChange = React.useCallback((event, option) => {
    setFirstDayOfWeek(option.key);
  }, []);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  }

  const handleTimeSelect = (dateTime) => {
    let formattedDateTime = moment(dateTime).format('DD-MMM-YYYY HH:mm:ss');
    props.handleSelectDateTime(formattedDateTime);
  }

  return (
    <div className={rootClass}>
      <DatePicker
        firstDayOfWeek={firstDayOfWeek}
        placeholder="Select a date..."
        ariaLabel="Select a date"
        minDate={today}
        // DatePicker uses English strings by default. For localized apps, you must override this prop.
        strings={defaultDatePickerStrings}
        onSelectDate={handleDateSelect}
      />
      { selectedDate && 
        <TimePickerComponent handleTimeChange={handleTimeSelect} date={selectedDate} />
      }
      
      { props.errorMessage &&
        <Text className="error-message" variant='small' nowrap block>
          {props.errorMessage}
        </Text>
      }
    </div>
  );
};


const TimePickerComponent = (props) => {
  const dateAnchor = props.date;
  const [minTime, setMinTime] = React.useState(new Date());
  const [timePickerError, setTimePickerError] = React.useState(null);

  const onTimechange = React.useCallback((_ev, time) => {
    // selected time must be a time in future
    if(time.getTime() < minTime.getTime()){
      setTimePickerError("Please select a time in future.");
    } else {
      setTimePickerError(null);
      props.handleTimeChange(time.toString());
    }

    return;
  }, []);

  const timeRange = {
    start: 8,
    end: 14,
  };

  return (
    <>
      <TimePicker
        placeholder="Pick a time"
        styles={timePickerStyles}
        useHour12
        allowFreeform
        autoComplete="on"
        // label="TimePicker basic example"
        onChange={onTimechange}
        dateAnchor={dateAnchor}
        minTime={minTime}
      />
      { timePickerError &&
        <Text className="error-message" variant='small' nowrap block>
          {timePickerError}
        </Text>
      }
    </>
  );
};
  