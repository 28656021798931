import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: false,
  isAuthenticated: false,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.value = action.payload;
      state.isAuthenticated = true;
    },
    logoutUser: (state, action) => {
      state.value = null;
      state.isAuthenticated = false;
    }
  },
})

export const { setUser, logoutUser } = userSlice.actions

export default userSlice.reducer