import * as React from "react";
import { Stack } from '@fluentui/react/lib/Stack';
import Header from "../components/Header";

export default function Layout({ children, header, className }) {
  return (
    <div className={`main-layout ${className}`}>
      <div className="main-content">
        { (typeof header != "undefined" && header) && header }
        <Stack horizontal tokens={stackTokens} styles={stackStyles}>
          <Stack {...columnProps} style={{width: "100%"}}>
              { children }
          </Stack>
        </Stack>
      </div>
    </div>
  )
}
  
// Styles definition
const stackTokens = { childrenGap: 50 };
const iconProps = { iconName: 'Calendar' };
const stackStyles = { root: { justifyContent: 'center' } };
const columnProps = {
  tokens: { childrenGap: 15, marginTop: '20' },
  styles: { root: { width: 300, justifyContent: 'center', margin: 20 } },
};