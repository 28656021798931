import moment from 'moment';
import RoamingSettingsHelper from "../helpers/roamingSettingsHelper";
import useAuthenticatedFetch from '../hooks/useAuthenticatedFetch';
import userService from './UserService';

class ROXSMSService {
  enqueueSMS = async (smsOptions) => {
    console.log('enqueueSMS', smsOptions);
    try {
      let user = await userService.getUser();
      console.log("user found", user);
      if(!user || !user?.accessToken?.token) {
        throw new Error("User not found");
      }

      let smsText = smsOptions.content;
      let contacts = smsOptions.contacts;
      let sendLaterDateTime = smsOptions.sendLater;
      let timezoneOffset = smsOptions.offset || moment().utcOffset();
      let recipients = [];

      contacts.forEach(function (contact, i) {
        recipients[i + 1] = {
          DestTn: contact.mobilePhone,
          DestName: contact.text,
          DestEmail: contact.email.address,
          SMSText: smsText,
        };
      })

      let config = {
        "Name": "ROX_API_Request",
        "Version": 1.1,
        "Product": "Office 365 SMS For Outlook",
        "Sender": {
          "Username": user.email,
          "AuthToken": user.accessToken.token
        },
        "Messages": {
          "ReceiptFlag": 1,
          "ReplyType": "H",
          "TimeZoneOffset": timezoneOffset,
          "Count": recipients.length - 1,
          "GlobalText": smsText,
          ...recipients,
        }
      }

      if (typeof sendLaterDateTime !== "undefined" && sendLaterDateTime) {
        config.Messages.SendLater = sendLaterDateTime;
        config.Messages.TimeZoneOffset = timezoneOffset;
      }

      console.log("SendSMS config", config);
      try {
        let fetchOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(config),
        }

        let smsResponse = await fetch(`${BACKEND_URL}smsapi/send`, fetchOptions);
        // let smsResponse = await fetch(`https://awsad.redoxygen.net/smsapi/send`, fetchOptions);
        if (!smsResponse.ok) {
          throw new Error(`HTTP error! Status: ${smsResponse.status}`);
        }
        let data = await smsResponse.json();
        console.log(data);
        return data;
      } catch (error) {
        throw error;
      }
    } catch (error) {
      console.error("Error sending sms", error);
      throw new Error(error);
    }
  }

  scheduleSMS = async (smsOptions) => {

  }
}

const getTimezoneDateOffset = async (selectedTimezone, date) => {
  const authenticatedFetch = useAuthenticatedFetch();
  try {
    const data = await authenticatedFetch(`common/find-offset`, {
      method: 'POST',
      body: {
        date,
        selectedTimezone,
      },
    });

    console.log("response", data);
  } catch (error) {
    console.log("Error fetching timezone date offset", error);
  }
}

const SMSService = new ROXSMSService();

export default SMSService;